.home-page {
  font-family: "PingFang";
  min-height: 100vh;
  position: relative;

  .header {
    width: 100vw;
    height: 100vh;
    background-color: #000;

    .header-main {
      min-width: 1280px;
      height: 100vh;
      background-image: url(../assets/images/page-header.png);
      background-size: cover;
      background-position: top center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    .slogen {
      font-family: PingFang SC;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 90px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }
    .description {
      width: 553px;
      margin-top: 40px;
      font-weight: 300;
      font-size: 13px;
      text-align: center;
      color: #ebebeb;
    }
    .more {
      border: 1px solid #ffffff;
      border-radius: 6px;
      font-size: 15px;
      width: 126px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-top: 62px;
    }
  }
  .scroll-screen {
    width: 100%;
    min-width: 1280px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    .scoll-menu {
      position: sticky;
      top: 0px;
      z-index: 3;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 37px;
      background-color: #fff;
      color: #000;
      .logo {
        width: 212px;
        text-align: right;
        img {
          width: 100px;
        }
      }
      .menu {
        flex: 1;
        font-weight: normal;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        padding-right: 40px;

        .menu-item {
          width: 100px;
          margin-left: 22px;

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

    .screen-item {
      width: 100%;
      position: relative;
      &.black-style {
        background: black !important;
        .center-text {
          color: #fff !important;
        }
        .line {
          border: 1px solid #fff !important;
        }
        .cur-line {
          background-color: #fff !important;
        }
        .tit  {
          color: #fff!important;
        }
      }
    }

    .screen-item-main {
      min-width: 1280px;
      margin: 0 auto;
    }

    .home {
      height: 100vh;
      min-width: 1280px;
      .down {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    .bussiness {
      height: 320px;
      padding-top: 84px;
      background-color: #fff;
      .title-bg {
        font-weight: 600;
        font-size: 144px;
        color: #f7f3f3;
        width: 100%;
        text-align: center;
        height: 202px;
        overflow: hidden;
      }
      .title {
        font-family: PingFang SC;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 90px;
        letter-spacing: 0em;
        text-align: left;
        color: #555555;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 140px;
      }
    }

    .business-style {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #ffffff 0%, #e8eff4 100%);
      height: 100vh;
      padding: 0 70px;
      .left-line {
        width: 80px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        .line {
          border: 1px solid #151515;
          width: 0;
          height: 519px;
        }
        .cur-line {
          position: absolute;
          right: -2px;
          top: 75px;
          background-color: #151515;
          border-radius: 3px;
          height: 84px;
          width: 6px;

          &.th1 {
            top: 75px;
          }
          &.th2 {
            top: 145px;
          }
          &.th3 {
            top: 215px;
          }
          &.th4 {
            top: 285px;
          }
          &.th5 {
            top: 355px;
          }
        }
      }
      .right-line {
        width: 140px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .line {
          border: 1px solid #151515;
          width: 0;
          height: 519px;
        }
        .cur-line {
          position: absolute;
          left: -2px;
          top: 75px;
          background-color: #151515;
          border-radius: 3px;
          height: 84px;
          width: 6px;

          &.th1 {
            top: 75px;
          }
          &.th2 {
            top: 145px;
          }
          &.th3 {
            top: 215px;
          }
          &.th4 {
            top: 285px;
          }
          &.th5 {
            top: 355px;
          }
        }
      }
      .center-text {
        width: 530px;
        padding: 15px 30px 50px 50px;
        color: #151515;
        .business-no {
          font-family: PingFang SC;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
          padding-bottom: 40px;
        }
        .business-name {
          font-family: PingFang SC;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 50px;
          letter-spacing: 0em;
          text-align: left;
          padding-bottom: 47px;
        }
        .business-description {
          font-weight: normal;
          font-size: 18px;
          padding-bottom: 47px;
        }
        .business-more {
          img {
            width: 153px;
          }
          display: inline-block;
        }
        .business-more:hover + .business-case {
          visibility: visible;
          animation: fadeIn;
          animation-duration: 0.9s;
        }
        .business-case {
          display: flex;
          visibility: hidden;
          padding-top: 28px;

          &:hover {
            visibility: visible;
            animation: fadeIn;
            animation-duration: 0.9s;
          }
          .case-item {
            width: 103px;
            height: 59px;
            margin-right: 8px;
            img {
              width: 103px;
              height: 59px;
            }
            .tit {
              font-size: 12px;
              padding-top: 6px;
              color: #000;
            }
          }
        }
      }
      .right-model {
        flex: 1;
        img {
          width: 80%;
        }
      }
    }

    .business-customer {
      background-color: #000;
      height: 871px;
      position: relative;
      padding-top: 84px;
      .custom-bg-title {
        font-size: 144px;
        color: #232323;
        width: 100%;
        text-align: center;
      }
      .custom-title {
        font-family: PingFang SC;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 90px;
        letter-spacing: 0em;
        text-align: left;
        color: #b3b3b3;
        position: absolute;
        top: 142px;
        left: 50%;
        transform: translateX(-50%);
      }
      .custom-case {
        padding-top: 80px;
        padding-bottom: 40px;
        .scrollpanel {
          display: flex;
          flex-direction: column;
          height: 100%;
          .row {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            .customer-card {
              width: 192px;
              height: 118px;
              border-radius: 9px;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 48px;
              margin-left: 29px;

              img {
                max-width: 80%;
                max-height: 85%;
              }

              .br20 {
                border-radius: 20px;
              }

              .w60 {
                width: 60%;
              }

              .w100 {
                max-width: 100%;
                max-height: 100%;
              }

              .h70 {
                height: 70%;
              }
            }
          }
        }
      }
    }
    .business-contact {
      background-color: #fff;
      box-sizing: border-box;
      padding: 65px 80px 80px 150px;
      .company-name {
        margin-bottom: 40px;
        margin-left: -10px;
        img{
          width: 135px;
          height: 75px;
        }
      }
      .company-info {
        display: flex;
        align-items: flex-start;
        width: 100%;
        h5 {
          font-weight: normal;
          font-size: 15px;
          color: #000000;
          padding-bottom: 28px;
        }
        p {
          font-weight: normal;
          font-size: 12px;
          color: #605e5e;
        }
        .company-vision {
          flex: 1;
        }
        .company-contact {
          flex: 1;
        }
        .company-join {
          flex: 1;
        }
        .company-social {
          flex: 1;
          button {
            width: 139px;
            height: 35px;
            border: 1px solid #000000;
            border-radius: 24.5px;
            font-size: 12px;
            color: #605e5e;
            background-color: #fff;
            display: block;
            margin-bottom: 24px;
            &:hover {
              color: blue;
              border: 1px solid blue;
            }
          }
        }
      }
      .company-social {
        position: relative;
        .weixin-qrcode {
          width: 116px;
          height: 116px;
          position: absolute;
          left: 200px;
          top: 40px;
          img {
            width: 116px;
            height: 116px;
          }
        }
        .linkin-qrcode {
          width: 116px;
          height: 116px;
          position: absolute;
          left: 200px;
          top: 40px;
          img {
            width: 116px;
            height: 116px;
          }
        }
      }
    }
  }
  
  .fixed-msg {
    position: fixed;
    right: 50px;
    bottom: 60px;
    display: inline-block;
    width: 36px;
    height: 36px;

    border-radius: 19px;
    z-index: 10;
    background-image: url(../assets/images/msg1.png);
    background-position: center;
    cursor: pointer;

    &:hover {
      background-image: url(../assets/images/msg2.png);
    }
  }
}

