.home-page {
  font-family: "PingFang";
  min-height: 100vh;
  position: relative;
}
.home-page .header {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
.home-page .header .header-main {
  min-width: 1280px;
  height: 100vh;
  background-image: url(page-header.8c1723c8.png);
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.home-page .header .slogen {
  font-family: PingFang SC;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.home-page .header .description {
  width: 553px;
  margin-top: 40px;
  font-weight: 300;
  font-size: 13px;
  text-align: center;
  color: #ebebeb;
}
.home-page .header .more {
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-size: 15px;
  width: 126px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 62px;
}
.home-page .scroll-screen {
  width: 100%;
  min-width: 1280px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.home-page .scroll-screen .scoll-menu {
  position: sticky;
  top: 0px;
  z-index: 3;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 37px;
  background-color: #fff;
  color: #000;
}
.home-page .scroll-screen .scoll-menu .logo {
  width: 212px;
  text-align: right;
}
.home-page .scroll-screen .scoll-menu .logo img {
  width: 100px;
}
.home-page .scroll-screen .scoll-menu .menu {
  flex: 1;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}
.home-page .scroll-screen .scoll-menu .menu .menu-item {
  width: 100px;
  margin-left: 22px;
}
.home-page .scroll-screen .scoll-menu .menu .menu-item a {
  color: inherit;
  text-decoration: none;
}
.home-page .scroll-screen .screen-item {
  width: 100%;
  position: relative;
}
.home-page .scroll-screen .screen-item.black-style {
  background: black !important;
}
.home-page .scroll-screen .screen-item.black-style .center-text {
  color: #fff !important;
}
.home-page .scroll-screen .screen-item.black-style .line {
  border: 1px solid #fff !important;
}
.home-page .scroll-screen .screen-item.black-style .cur-line {
  background-color: #fff !important;
}
.home-page .scroll-screen .screen-item.black-style .tit {
  color: #fff !important;
}
.home-page .scroll-screen .screen-item-main {
  min-width: 1280px;
  margin: 0 auto;
}
.home-page .scroll-screen .home {
  height: 100vh;
  min-width: 1280px;
}
.home-page .scroll-screen .home .down {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.home-page .scroll-screen .bussiness {
  height: 320px;
  padding-top: 84px;
  background-color: #fff;
}
.home-page .scroll-screen .bussiness .title-bg {
  font-weight: 600;
  font-size: 144px;
  color: #f7f3f3;
  width: 100%;
  text-align: center;
  height: 202px;
  overflow: hidden;
}
.home-page .scroll-screen .bussiness .title {
  font-family: PingFang SC;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 140px;
}
.home-page .scroll-screen .business-style {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #ffffff 0%, #e8eff4 100%);
  height: 100vh;
  padding: 0 70px;
}
.home-page .scroll-screen .business-style .left-line {
  width: 80px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.home-page .scroll-screen .business-style .left-line .line {
  border: 1px solid #151515;
  width: 0;
  height: 519px;
}
.home-page .scroll-screen .business-style .left-line .cur-line {
  position: absolute;
  right: -2px;
  top: 75px;
  background-color: #151515;
  border-radius: 3px;
  height: 84px;
  width: 6px;
}
.home-page .scroll-screen .business-style .left-line .cur-line.th1 {
  top: 75px;
}
.home-page .scroll-screen .business-style .left-line .cur-line.th2 {
  top: 145px;
}
.home-page .scroll-screen .business-style .left-line .cur-line.th3 {
  top: 215px;
}
.home-page .scroll-screen .business-style .left-line .cur-line.th4 {
  top: 285px;
}
.home-page .scroll-screen .business-style .left-line .cur-line.th5 {
  top: 355px;
}
.home-page .scroll-screen .business-style .right-line {
  width: 140px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.home-page .scroll-screen .business-style .right-line .line {
  border: 1px solid #151515;
  width: 0;
  height: 519px;
}
.home-page .scroll-screen .business-style .right-line .cur-line {
  position: absolute;
  left: -2px;
  top: 75px;
  background-color: #151515;
  border-radius: 3px;
  height: 84px;
  width: 6px;
}
.home-page .scroll-screen .business-style .right-line .cur-line.th1 {
  top: 75px;
}
.home-page .scroll-screen .business-style .right-line .cur-line.th2 {
  top: 145px;
}
.home-page .scroll-screen .business-style .right-line .cur-line.th3 {
  top: 215px;
}
.home-page .scroll-screen .business-style .right-line .cur-line.th4 {
  top: 285px;
}
.home-page .scroll-screen .business-style .right-line .cur-line.th5 {
  top: 355px;
}
.home-page .scroll-screen .business-style .center-text {
  width: 530px;
  padding: 15px 30px 50px 50px;
  color: #151515;
}
.home-page .scroll-screen .business-style .center-text .business-no {
  font-family: PingFang SC;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 40px;
}
.home-page .scroll-screen .business-style .center-text .business-name {
  font-family: PingFang SC;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 47px;
}
.home-page .scroll-screen .business-style .center-text .business-description {
  font-weight: normal;
  font-size: 18px;
  padding-bottom: 47px;
}
.home-page .scroll-screen .business-style .center-text .business-more {
  display: inline-block;
}
.home-page .scroll-screen .business-style .center-text .business-more img {
  width: 153px;
}
.home-page .scroll-screen .business-style .center-text .business-more:hover + .business-case {
  visibility: visible;
  animation: fadeIn;
  animation-duration: 0.9s;
}
.home-page .scroll-screen .business-style .center-text .business-case {
  display: flex;
  visibility: hidden;
  padding-top: 28px;
}
.home-page .scroll-screen .business-style .center-text .business-case:hover {
  visibility: visible;
  animation: fadeIn;
  animation-duration: 0.9s;
}
.home-page .scroll-screen .business-style .center-text .business-case .case-item {
  width: 103px;
  height: 59px;
  margin-right: 8px;
}
.home-page .scroll-screen .business-style .center-text .business-case .case-item img {
  width: 103px;
  height: 59px;
}
.home-page .scroll-screen .business-style .center-text .business-case .case-item .tit {
  font-size: 12px;
  padding-top: 6px;
  color: #000;
}
.home-page .scroll-screen .business-style .right-model {
  flex: 1;
}
.home-page .scroll-screen .business-style .right-model img {
  width: 80%;
}
.home-page .scroll-screen .business-customer {
  background-color: #000;
  height: 871px;
  position: relative;
  padding-top: 84px;
}
.home-page .scroll-screen .business-customer .custom-bg-title {
  font-size: 144px;
  color: #232323;
  width: 100%;
  text-align: center;
}
.home-page .scroll-screen .business-customer .custom-title {
  font-family: PingFang SC;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: left;
  color: #b3b3b3;
  position: absolute;
  top: 142px;
  left: 50%;
  transform: translateX(-50%);
}
.home-page .scroll-screen .business-customer .custom-case {
  padding-top: 80px;
  padding-bottom: 40px;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card {
  width: 192px;
  height: 118px;
  border-radius: 9px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-left: 29px;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card img {
  max-width: 80%;
  max-height: 85%;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card .br20 {
  border-radius: 20px;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card .w60 {
  width: 60%;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card .w100 {
  max-width: 100%;
  max-height: 100%;
}
.home-page .scroll-screen .business-customer .custom-case .scrollpanel .row .customer-card .h70 {
  height: 70%;
}
.home-page .scroll-screen .business-contact {
  background-color: #fff;
  box-sizing: border-box;
  padding: 65px 80px 80px 150px;
}
.home-page .scroll-screen .business-contact .company-name {
  margin-bottom: 40px;
  margin-left: -10px;
}
.home-page .scroll-screen .business-contact .company-name img {
  width: 135px;
  height: 75px;
}
.home-page .scroll-screen .business-contact .company-info {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.home-page .scroll-screen .business-contact .company-info h5 {
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  padding-bottom: 28px;
}
.home-page .scroll-screen .business-contact .company-info p {
  font-weight: normal;
  font-size: 12px;
  color: #605e5e;
}
.home-page .scroll-screen .business-contact .company-info .company-vision {
  flex: 1;
}
.home-page .scroll-screen .business-contact .company-info .company-contact {
  flex: 1;
}
.home-page .scroll-screen .business-contact .company-info .company-join {
  flex: 1;
}
.home-page .scroll-screen .business-contact .company-info .company-social {
  flex: 1;
}
.home-page .scroll-screen .business-contact .company-info .company-social button {
  width: 139px;
  height: 35px;
  border: 1px solid #000000;
  border-radius: 24.5px;
  font-size: 12px;
  color: #605e5e;
  background-color: #fff;
  display: block;
  margin-bottom: 24px;
}
.home-page .scroll-screen .business-contact .company-info .company-social button:hover {
  color: blue;
  border: 1px solid blue;
}
.home-page .scroll-screen .business-contact .company-social {
  position: relative;
}
.home-page .scroll-screen .business-contact .company-social .weixin-qrcode {
  width: 116px;
  height: 116px;
  position: absolute;
  left: 200px;
  top: 40px;
}
.home-page .scroll-screen .business-contact .company-social .weixin-qrcode img {
  width: 116px;
  height: 116px;
}
.home-page .scroll-screen .business-contact .company-social .linkin-qrcode {
  width: 116px;
  height: 116px;
  position: absolute;
  left: 200px;
  top: 40px;
}
.home-page .scroll-screen .business-contact .company-social .linkin-qrcode img {
  width: 116px;
  height: 116px;
}
.home-page .fixed-msg {
  position: fixed;
  right: 50px;
  bottom: 60px;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 19px;
  z-index: 10;
  background-image: url(msg1.79a4732e.png);
  background-position: center;
  cursor: pointer;
}
.home-page .fixed-msg:hover {
  background-image: url(msg2.6ebecc75.png);
}

/*# sourceMappingURL=index.d8753035.css.map */
